<template>
  <div>
    <div v-if="type == 0">
      <div class="flex flex-between flex-v-center">
        <el-menu :default-active="tab" mode="horizontal" @select="handleSelect">
          <el-menu-item :index="sort.title" class="margin-x" v-for="(sort, index) in $store.getters.getSorts(type)"
            :key="index">
            <h3>{{ sort.title }}</h3>
          </el-menu-item>
        </el-menu>
        <div class="flex flex-v-center">
          <span class="mt bold color-primary">{{ $t('必练题型') }}:</span>
          <el-select @change="query(1)" v-model="score" :style="{ width: $store.state.lang == 'en' ? '150px' : '120px' }"
            class="margin-x select bold" placeholder="">
            <el-option :value="79" :label="$num(79) + $t('分')"></el-option>
            <el-option :value="65" :label="$num(65) + $t('分')"></el-option>
            <el-option :value="50" :label="$num(50) + $t('分')"></el-option>
            <el-option :value="35" :label="$t('35分以下')"></el-option>
          </el-select>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div id="scroll-box" @scroll="scroll">
      <el-card :id="sort.title" class="margin-x margin-lt" v-for="(sort, index) in $store.getters.getSorts(type)"
        :key="index">
        <div class="flex flex-between flex-v-center">
          <div class="margin-x">
            <el-image :src="$host + sort.logo" style="width:100px;" fit="contain"></el-image>
            <h3 class="lt center margin-t">{{ sort.title }}</h3>
          </div>
          <div class="margin-x flex1">
            <el-menu default-active="0" router>
              <el-menu-item :id="type.title" v-for="(type, index1) in sort.types" :key="index1"
                :index="'/main/' + tag + '?tag=' + type.tag" class="border br margin" style="height:80px">
                <div class="flex flex-between flex-v-center" style="height:100%">
                  <div class="flex flex-v-center">
                    <el-image :src="$host + type.logo" style="width:60px;height: 60px;"></el-image>
                    <h4 class="margin-x mt">{{ type.title }} ({{ type.sources_count }})</h4>
                  </div>
                  <el-tag size="small" effect="dark" v-if="check(type)" type="danger">
                    {{ $t('必练') }}
                  </el-tag>
                </div>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  name: "practise",
  data() {
    return {
      tab: "",
      offsetTop: 0,
      score: 79,
      tag: 'pte',
      type: 0
    };
  },

  watch: {
    "$store.state.sorts": {
      handler: function () {
        this.$nextTick(() => {
          if (!this.tab) {
            this.tab = this.$store.getters.getSorts(this.type)[0].title;
          }
          this.jump(this.tab);
        })
      }
    },
    $route(val) {
      this.tag = val.query.tag || 'pte';
      this.type = this.tag == 'ccl' ? 1 : 0;
    }
  },

  created() {
    this.tag = this.$route.query.tag || 'pte';
    this.type = this.tag == 'ccl' ? 1 : 0;
    let hash = this.$route.hash;

    if (hash) {
      this.tab = hash.replace("#", '');
      if (this.$store.state.sorts.length > 0) {
        this.$nextTick(() => {
          this.jump(this.tab);
        })
      }
    } else {
      if (this.$store.state.sorts.length > 0) {
        this.tab = this.$store.getters.getSorts(this.type)[0].title;
      }
    }
  },

  methods: {
    check(type) {
      let items = type.score_range.split(",");
      let flag = false;
      if (this.score > parseInt(items[0]) && this.score <= parseInt(items[1])) {
        flag = true;
      }
      return flag;
    },
    handleSelect(index) {
      this.jump(index)
    },
    bodyScroll(val) {
      console.log(val)
    },
    scroll() {
      const box = document.getElementById('scroll-box')
      // 若当前设置的滚动高度大于实际滚动的高度，即为锚点跳转，不再设置选中的锚点
      if (this.offsetTop > box.scrollTop) {
        this.offsetTop = 0
        return
      }
      let totalH = 0
      this.$store.state.sorts.some(anchor => {
        let scrollItem = document.getElementById(anchor.title)// 锚点对应的模块
        totalH = totalH + scrollItem.clientHeight
        let judge = box.scrollTop < totalH
        if (judge) {
          this.tab = anchor.title
          return true
        }
      })
    },
    jump(id) {
      this.activeStep = id // 设置选中的锚点为当前点击的
      const box = document.getElementById('scroll-box')
      let scrollItem = document.getElementById(id)
      // 锚点对应的模块与最近的一个具有定位的祖宗元素顶部的距离
      this.offsetTop = scrollItem.offsetTop - 106;
      box.scrollTo({
        top: scrollItem.offsetTop - 106,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scope>
.adv {
  height: 60px;
  padding: 0 20px;
  background: linear-gradient(90deg, #f57a54, #ff9f7b);
  margin-bottom: 20px;
  opacity: .9;
  border-radius: 20px;
}


#scroll-box {
  /* margin-top: 20px; */
  height: calc(100vh - 240px);
  overflow-y: scroll;
}
</style>
